require("intl-tel-input/build/js/utils")

// Internation Tel 
import intlTelInput from 'intl-tel-input';

document.addEventListener("turbolinks:load", () => {
	var phone_input = document.getElementById("phone");
	var employer_phone = document.getElementById("employer_phone");
	var emergency_phone = document.getElementById("emergency_phone");
	var addressDropdown = document.querySelector("#country-select");


	if (phone_input) {
		//Initialize intltel
		var phone_input_iti = intlTelInput(phone_input, {
			initialCountry: 'us',
			separateDialCode: true
		});

		var reset = function() {
		  phone_input.classList.remove("is-valid");
		  phone_input.classList.remove("is-invalid"); 
		};

		// on blur: validate
		phone_input.addEventListener('blur', function() {
		  reset();
		  if (phone_input.value.trim()) {
		    if (phone_input_iti.isValidNumber()) {
		      phone_input.classList.add("is-valid");
		    } else {
		      phone_input.classList.add("is-invalid");
		    }
		  }
		});

		// Handle Counrty DropDown auto select countrycode
		// listen to the address dropdown for changes
		addressDropdown.addEventListener('change', function() {
		  phone_input_iti.setCountry(this.value);
		});


		// on keyup / change flag: reset
		phone_input.addEventListener('change', reset);
		phone_input.addEventListener('keyup', reset);
	}

	if (emergency_phone) {
		//Initialize intltel
		var emergency_phone_iti = intlTelInput(emergency_phone, {
			initialCountry: 'us',
			separateDialCode: true
		});


		var reset_emergency_phone = function() {
		  emergency_phone.classList.remove("is-valid");
		  emergency_phone.classList.remove("is-invalid"); 
		};

		// on blur: validate
		emergency_phone.addEventListener('blur', function() {
		  reset_emergency_phone();
		  if (emergency_phone.value.trim()) {
		    if (emergency_phone_iti.isValidNumber()) {
		      emergency_phone.classList.add("is-valid");
		    } else {
		      emergency_phone.classList.add("is-invalid");
		    }
		  }
		});
		// on keyup / change flag: reset
		emergency_phone.addEventListener('change', reset);
		emergency_phone.addEventListener('keyup', reset);
	}

	if (employer_phone) {
		//Initialize intltel
		var employer_phone_iti = intlTelInput(employer_phone, {
			initialCountry: 'us',
			separateDialCode: true
		});


		var reset_employer_phone = function() {
		  employer_phone.classList.remove("is-valid");
		  employer_phone.classList.remove("is-invalid"); 
		};

		// on blur: validate
		employer_phone.addEventListener('blur', function() {
		  reset_employer_phone();
		  if (employer_phone.value.trim()) {
		    if (employer_phone_iti.isValidNumber()) {
		      employer_phone.classList.add("is-valid");
		    } else {
		      employer_phone.classList.add("is-invalid");
		    }
		  }
		});
		// on keyup / change flag: reset
		employer_phone.addEventListener('change', reset);
		employer_phone.addEventListener('keyup', reset);
	}
});