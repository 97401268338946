// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('datatables.net-bs4')
require('packs/datatables')
require('packs/intl_tel_input')

// Jquery
import $ from 'jquery';
global.$ = jQuery;

// Bootstrap
import * as bootstrap from 'bootstrap'

// Signature Pad
import SignaturePad from 'signature_pad/dist/signature_pad.min.js';

// Flackpickr
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")


// Chartkick
import "chartkick/chart.js"


document.addEventListener("turbolinks:load", () => {
	// Flackpickr
	flatpickr("[data-behavior='datepicker']", {
		altInput: true,
		altFormat: "F j, Y",
		dataFormot: "Y-m-d",
	})

	// Handle flash-alert delay
	$(".flash-alert").delay(2000).fadeOut(300, function() { $(this).remove(); });

  // Tooltip
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
  
	// Handle toggle for dependant form
	var toggle = document.getElementById("toggleShow");
	if (toggle) {
		toggle.addEventListener("click", function() {
			var toggle_element = document.getElementById("toggle-dependant-add");
			var button_element = document.getElementById("toggleShow");
			toggle_element.style.display = toggle_element.style.display == 'none' ? 'block' : 'none';
			button_element.style.display = 'none';
		});
	}

	// Signature Pad
	var signaturePadCanvas = document.getElementById('signature-pad')

	if (signaturePadCanvas) {
		var signaturePad = new SignaturePad(signaturePadCanvas, {
		  backgroundColor: 'rgba(255, 255, 255, 0.2)',
		  penColor: 'rgb(0, 0, 0)'
		});

		var cancelButton = document.getElementById('signature_pad_clear');
		cancelButton.addEventListener('click', function (event) {
	  	signaturePad.clear();
		});

		var saveButton = document.getElementById('signature_pad_save');

		saveButton.addEventListener('click', function (event) {
	   	$('#user_signature').val(signaturePad.toDataURL());
		});
	}
});

// stylesheets
require("stylesheets/application.scss")
